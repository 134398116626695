var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center line-height-1 pb-2"},[_c('i',{staticClass:"text-primary ti ti-user-check small-gap-right"}),_c('h4',{staticClass:"text-primary mb-0"},[_vm._v(" Daftar Surat Penugasan ")])]),_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('div',{staticClass:"align-items-end"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","size":"sm","to":{
                      name: _vm.checkAuthorizeRole('letter-of-assignments.create'),
                    }}},[_c('i',{staticClass:"ti ti-plus small-gap-right"}),_vm._v(" Tambah ")])],1)])],1)],1),_c('vue-good-table',{attrs:{"mode":"remote","is-loading":_vm.isLoading,"total-rows":_vm.totalRecords,"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
              enabled: true,
              initialSortBy: { field: 'name', type: 'asc' },
            },"select-options":{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
              enabled: true,
              nextLabel: 'selanjutnya',
              prevLabel: 'sebelumnya',
              rowsPerPageLabel: 'Data per halaman',
              ofLabel: 'dari',
              pageLabel: 'halaman', // for 'pages' mode
              allLabel: 'Semua',
            },"line-numbers":true},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
            var column = ref.column;
            var updateFilters = ref.updateFilters;
return [(column.field == 'date')?_c('b-form-datepicker',{staticStyle:{"z-index":"9999"},attrs:{"size":"sm","placeholder":"Tanggal","date-format-options":{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                },"locale":"id"},on:{"input":function (value) { return updateFilters(column, value); }}}):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'assign_name')?_c('span',{staticClass:"justify-content-start"},[_c('div',_vm._l((props.row
                      .letter_of_assignment_participants),function(item,index){return _c('b-badge',{key:index,staticClass:"small-gap-right cursor-pointer",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push({
                        name: 'users.edit',
                        params: { id: item.user_id },
                      })}}},[_vm._v(" "+_vm._s(item.user.name)+" ")])}),1)]):_vm._e(),(props.column.field === 'is_public')?_c('span',{staticClass:"d-flex justify-content-start"},[_c('b-badge',{staticClass:"small-gap-right",attrs:{"variant":_vm._f("statusClass")(props.row.is_public)}},[_vm._v(" "+_vm._s(props.row.is_public ? "public" : "private")+" ")])],1):(props.column.field === 'file')?_c('a',{staticClass:"d-flex justify-content-start",attrs:{"href":props.row.file,"target":"__blank"}},[_c('b-badge',{staticClass:"small-gap-right cursor-pointer",attrs:{"variant":"danger"}},[_vm._v(" Buka ")])],1):(props.column.field === 'action')?_c('span',{staticClass:"d-flex justify-content-start"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"info","size":"sm"},on:{"click":function($event){return _vm.handleShow(props.row)}}},[_c('i',{staticClass:"ti ti-zoom-in"})]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning","size":"sm"},on:{"click":function($event){return _vm.handleEdit(props.row)}}},[_c('i',{staticClass:"ti ti-pencil"})]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.handleDelete(props.row)}}},[_c('i',{staticClass:"ti ti-trash"})])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }